import { useEffect, useState } from 'react';
import { AvatarsStacked, Box, Image, Text } from '@mentimeter/ragnar-ui';
import { blueLightBase } from '@mentimeter/ragnar-colors';
import {
  useOrganizationMembersUnpaginated,
  useWorkspace,
} from '@mentimeter/workspace-data-hooks';
import { useBillingHistory } from '@mentimeter/billing';
import {
  BillingCycleSuccessSummary,
  CheckoutHeading,
  CloseModal,
  GenericUpgradeError,
  LicensesSuccessSummary,
  LicenseSummary,
  PlanBlock,
  PlansPreview,
  PlansSuccessSummary,
  UpgradeNotPossible,
  UpgradePermissionDenied,
} from '@mentimeter/checkout-ui';
import { MemberList, transformMembersToAvatars } from '@mentimeter/workspace';
import { PlanCategory } from '@mentimeter/http-clients';
import { trackCheckoutEvent } from '@mentimeter/checkout-data-hooks';
import { useTranslation } from '@mentimeter/i18n';
import { trackUser } from '@api/tracking/client';
import { useIsUpgradeAvailable, useUserPlansData } from '../data-hooks/hooks';
import { ModalLayout } from '../components/ModalLayout';
import type { CheckoutModalType } from '../types';
import { PaymentMethodDataHandler } from './PaymentMethodDataHandler';
import { PurchaseSummaryDataHandler } from './PurchaseSummaryDataHandler';
import { LicensesSelectorDataHandler } from './LicensesSelectorDataHandler';

function planCategoryToPlanName(plan: PlanCategory): string {
  switch (plan) {
    case PlanCategory.BASIC:
      return 'Basic';
    case PlanCategory.PRO:
      return 'Pro';
    default:
      return '';
  }
}

interface CheckoutPopupT {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  plan: PlanCategory;
  type: CheckoutModalType;
  isCheckoutModalEnabled?: boolean;
}

export const CheckoutModal = ({
  open,
  onOpenChange,
  plan,
  type,
  isCheckoutModalEnabled,
}: CheckoutPopupT) => {
  useEffect(() => {
    if (open) {
      trackUser({
        event: 'Opened checkout modal',
        properties: {
          context: window ? window?.location?.pathname : 'Unknown',
        },
      });
    }
  }, [open]);
  if (!isCheckoutModalEnabled) return null;

  const handleOnCloseModal = () => onOpenChange(false);

  return (
    <ModalLayout open={open} onOpenChange={onOpenChange}>
      <CloseModal onCloseModal={handleOnCloseModal} />
      <ModalContent
        plan={plan}
        type={type}
        handleCloseModal={handleOnCloseModal}
      />
    </ModalLayout>
  );
};

interface ModalContentProps {
  plan: PlanCategory;
  type: CheckoutModalType;
  handleCloseModal: () => void;
}

function ModalContent({ plan, type, handleCloseModal }: ModalContentProps) {
  const {
    upgradeAvailable,
    upgradeNotPossible,
    upgradeForbidden,
    hasUnpaidInvoice,
    isOwner,
    isLoading: isLoadingUpgradePossibilities,
  } = useIsUpgradeAvailable();
  const { t } = useTranslation('checkoutModalPackage');

  const { data: workspace } = useWorkspace();
  const { receipts } = useBillingHistory();
  const { members } = useOrganizationMembersUnpaginated();
  const [successState, setSuccessState] = useState<boolean>(false);
  const [selectedLicenses, setSelectedLicenses] = useState<number>(() => {
    // If we are upgrading the plan or changing billing cycle, we want to keep the same amount of licenses
    // If we are adding licenses, we want to start with 1 additional license all the time
    return type === 'licenses' ? 1 : 0;
  });

  const currentLicensesAmount = workspace?.activeSubscription?.licenses ?? 0;
  const { currentPlan, newPlan, hasTax, taxPercentage, isLoading } =
    useUserPlansData({
      plan,
      licenses: selectedLicenses + currentLicensesAmount,
    });

  if (!isLoadingUpgradePossibilities) {
    if (upgradeForbidden) return <GenericUpgradeError />;
    if (!isOwner) return <UpgradePermissionDenied />;
    if (upgradeNotPossible || hasUnpaidInvoice) return <UpgradeNotPossible />;
    if (!upgradeAvailable) return null;
  }

  const subHeadingMapping = (
    type: CheckoutModalType,
    currentPlan: string | undefined,
  ) => {
    switch (type) {
      case 'plan':
        return t('sub_headings.upgrade_from', { currentPlan });
      case 'licenses':
        return t('sub_headings.purchase_additional_licenses');
      case 'billing-cycle':
        return t('sub_headings.change_billing_cycle');
    }
  };

  return (
    <Box
      flexDirection={['column', 'column', 'row']}
      width="100%"
      alignItems="stretch"
    >
      <Box flex="1 1 auto">
        {successState && (
          <Box
            bg={blueLightBase}
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src="https://static.mentimeter.com/static/images/using-mentimeter-confetti.svg"
              alt="success state"
            />
          </Box>
        )}
        {!successState && (
          <Box
            alignItems={['center', 'center', 'start']}
            px={['space8', 'space10']}
            py="space18"
            width="100%"
          >
            <Box maxWidth={['456px', '488px', '100%']} width="100%">
              <CheckoutHeading
                heading={`Mentimeter ${planCategoryToPlanName(plan)}`}
                isLoading={isLoading}
                subHeading={subHeadingMapping(type, currentPlan?.name)}
              />
              <Box width="100%" mt="space4">
                {(type === 'plan' || type === 'billing-cycle') && (
                  <PlansPreview
                    isLoading={isLoading}
                    currentPlan={
                      <PlanBlock
                        type="current"
                        planName={currentPlan?.name}
                        licenses={currentPlan?.licenses}
                        cycle={currentPlan?.cycle}
                        price={currentPlan?.price}
                        hasTax={hasTax}
                        taxPercentage={taxPercentage}
                      />
                    }
                    newPlan={
                      <PlanBlock
                        type="new"
                        planName={newPlan?.name}
                        licenses={newPlan?.licenses}
                        cycle={newPlan?.cycle}
                        price={newPlan?.price}
                        hasTax={hasTax}
                        taxPercentage={taxPercentage}
                      />
                    }
                  />
                )}
                {type === 'licenses' && currentLicensesAmount && (
                  <LicenseSummary
                    isLoading={isLoading}
                    currentLicenses={currentLicensesAmount}
                    currentPlan={currentPlan?.name}
                  >
                    <AvatarsStacked
                      size="large"
                      avatars={transformMembersToAvatars(members)}
                      totalItems={members.length}
                      maxItems={3}
                      popOverContent={<MemberList members={members} />}
                    />
                  </LicenseSummary>
                )}
              </Box>
              <Text fontSize="87.5" fontWeight="semiBold" mt="space5">
                {type === 'licenses'
                  ? t('licenses_title_how_many')
                  : t('licenses_title_additional')}
              </Text>
              <LicensesSelectorDataHandler
                type={type}
                onLicensesChange={setSelectedLicenses}
                currentLicensesAmount={currentLicensesAmount}
                trackCheckoutEvent={trackCheckoutEvent}
              />
              <Text fontSize="87.5" fontWeight="semiBold" mt="space8">
                {t('payment_method_title')}
              </Text>
              <PaymentMethodDataHandler />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        width={['100%', '100%', 405]}
        py="space18"
        px={['space8', 'space10']}
        bg="secondaryWeakest"
        alignItems={['center', 'center', 'unset']}
      >
        {successState && (
          <>
            {type === 'licenses' && (
              <LicensesSuccessSummary
                addedLicenses={selectedLicenses}
                handleCloseModal={handleCloseModal}
              />
            )}
            {type === 'billing-cycle' && (
              <BillingCycleSuccessSummary
                invoiceUrl={receipts?.[0]?.invoice_url ?? '/app/billing'}
                billingCycle={newPlan?.cycle ?? 'yearly'}
              />
            )}
            {type === 'plan' && (
              <PlansSuccessSummary
                heading={t('success_title', {
                  plan: planCategoryToPlanName(plan),
                })}
                text={
                  plan === PlanCategory.PRO ||
                  plan === PlanCategory.EDUCATIONAL_PRO
                    ? t('success_text_pro')
                    : t('success_text_basic')
                }
                invoiceUrl={receipts?.[0]?.invoice_url ?? '/app/billing'}
              />
            )}
          </>
        )}
        {!successState && (
          <Box maxWidth={['456px', '488px', '100%']}>
            <PurchaseSummaryDataHandler
              plan={plan}
              numberOfLicenses={
                (selectedLicenses ?? (type === 'plan' ? 0 : 1)) +
                currentLicensesAmount
              }
              type={type}
              onSubmitSuccess={() => setSuccessState(true)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
