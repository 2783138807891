import { Avatar, Box, Text, TextEllipsis } from '@mentimeter/ragnar-ui';
import type { CSSProperties } from 'react';
import { getNameInitials } from '@mentimeter/user';
import type { WorkspaceRole } from '@mentimeter/http-clients';
import { VirtualizedList } from '@mentimeter/virtualized';
import type { MemberT } from '../utils/member-avatar-utils';
import { AVATAR_TYPE_BY_MEMBER_STATUS_MAP } from '../utils/member-avatar-utils';
import { getRoleDisplayName } from '..';

interface ItemsRendererT {
  index: number;
  style: CSSProperties;
}

interface OnItemsRenderedT {
  visibleStopIndex: number;
}

interface MemberListT {
  members: MemberT[];
  loadMoreItems?: () => void;
}

interface RowItemT {
  item: MemberT;
  style: CSSProperties;
}

const RowItem = ({ item, style }: RowItemT) => {
  let itemRole: WorkspaceRole;
  if ('role' in item) itemRole = item.role;

  return (
    <Box
      width="100%"
      alignItems="center"
      key={`member-avatar-${item.id}`}
      flexDirection="row"
      px={3}
      style={style}
    >
      <Avatar
        size="large"
        initials={getNameInitials(item.name || '').toUpperCase()}
        name={item.name || ''}
        itemId={String(item.id)}
        type={AVATAR_TYPE_BY_MEMBER_STATUS_MAP[item.status] || 'user'}
        aria-label="member avatar"
        profilePictureUrl={item.profile_picture_url}
      />
      <Box ml={2} flex="1 1 auto" my="auto">
        <TextEllipsis
          text={item?.name || item.email}
          tooltipDescription={item?.name || item.email}
          id={`member-avatar-${item.id}`}
          fontWeight="bold"
          color="text"
        />
        {itemRole && <Text>{getRoleDisplayName(itemRole)}</Text>}
      </Box>
    </Box>
  );
};

export const MemberList = ({ members, loadMoreItems }: MemberListT) => {
  const onItemsRendered = ({ visibleStopIndex }: OnItemsRenderedT) => {
    if (members.length - 1 === visibleStopIndex) loadMoreItems?.();
  };

  const itemsRenderer = ({ index, style }: ItemsRendererT) => {
    const memberItem = members[index];
    if (typeof memberItem === 'undefined') return null;

    return <RowItem item={memberItem} style={style} />;
  };

  const itemCount = members.length;
  const itemSize = 63;
  // Make sure the list is not scrollable nor contains empty space
  // when items are fewer than max height
  const height = itemSize * members.length + 32;

  return (
    <Box height={`${height}px`} maxHeight="375px" width="250px" py={3}>
      <VirtualizedList
        itemCount={itemCount}
        itemsRenderer={itemsRenderer}
        itemSize={itemSize}
        onItemsRendered={onItemsRendered}
        overscanCount={3}
      />
    </Box>
  );
};
